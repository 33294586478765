import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';

// RxJs
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

// Services
import { UserService } from '@app/modules/core/services/user.service';
import { AuthService } from '@app/modules/core/services/auth.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isNotChangePasswordUrl = window.location.pathname !== '/change-password';

  constructor(
    private readonly userService: UserService,
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].includes(err.status)) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(result => !!result),
              take(1),
              switchMap(() => next.handle(this.addHeader(request)))
            );
          } else {
            this.refreshTokenInProgress = true;

            return this.refreshToken()
              .pipe(
                switchMap(({access_token}) => {
                  this.userService.setToken(access_token);
                  this.refreshTokenSubject.next(access_token);

                  return next.handle(this.addHeader(request));
                }),
                catchError(refreshError => {
                  this.userService.removeTokens();
                  if (this.isNotChangePasswordUrl) {
                    this.router.navigate(['/promo']);
                  }
                  return throwError(refreshError)
                }),
                tap(() => (this.refreshTokenInProgress = false))
              );
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addHeader(request: HttpRequest<any>): HttpRequest<any> {
    return  request.clone({
      setHeaders: {
        Authorization: `${this.auth.getToken()}`
      }
    });
  }

  private refreshToken(): Observable<any> {
    return this.auth.refreshToken();
  }
}
