import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

// Services
import { NotificationService } from '@app/modules/shared/services';

// Enums
import { NotificationStatusEnum } from '@app/modules/shared/enums';

@Component({
  selector: 'app-upload-video',
  templateUrl: 'upload-video.component.html',
  styleUrls: ['upload-video.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadVideoComponent),
      multi: true
    }
  ]
})

export class UploadVideoComponent {
  @Input() isFileUploaded = false;

  public imagePath;
  public videoURL: any;
  public videoTitle: string;
  public message: string;

  constructor(
      private readonly notifyService: NotificationService
    ) {
    }

  // Установить выбранное значение
  private setSelectedValue(v: any): void {
    this.propagateChange(v);
  }

  public preview(files) {
    this.videoURL = null;
    if (files.length === 0) {
      return;
    }

    const fileExt = files[ 0 ].name.split('.').pop();
    const isMkv = (fileExt.toLowerCase() === 'mkv');

    const mimeType = files[ 0 ].type;
    if (!isMkv && mimeType.match(/video\/*/) == null) {
      this.notifyService.show({
        status: NotificationStatusEnum.Error,
        text: `This file format is not supported.`
      });
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[ 0 ]);
    reader.onload = (_event) => {
      // const video = document.createElement('video');
      // video.preload = 'metadata';
      // video.src = URL.createObjectURL(files[0]);
      //
      // video.onloadedmetadata = function() {
      //   // TODO: тут есть чутка инфы о видео
      //   // console.log(video)
      //   // console.log(video.duration)
      // }
      this.videoTitle = files[0].name;
      this.videoURL = reader.result;
    }

    this.setSelectedValue(files);
  }


  /** Связываем через ngModel */
  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this.setSelectedValue(value);
  }

  /** Выбор значения селекта */
  public selectValue(value: any): void {
    this.setSelectedValue(value);
  }

  // Метод интерфейса ControlValueAccessor
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Метод интерфейса ControlValueAccessor
  // tslint:disable-next-line:no-empty
  public registerOnTouched(fn: any): void {
  }

  // Колбэк на value change
  // tslint:disable-next-line:no-empty
  public propagateChange = (fn: any): void => {
  };
}
